import { Box, Flex, Text } from '@chakra-ui/react'
import { colors } from 'styles/foundations'

export const SingleFeatureText = ({
  active,
  text,
  description,
  scrollTo,
  code
}: {
  active: string
  setActive: (code: string) => void
  text: string
  description: string
  scrollTo: number
  code: string
}) => {
  return (
    <Flex
      flexDirection="row"
      mt={10}
      gap="1rem"
      data-aos-duration={'200'}
      alignItems="center"
      style={{
        opacity: active === code ? 1 : 0.7
      }}
      cursor="pointer"
      onClick={() => {
        window.scrollTo && window.scrollTo(0, scrollTo)
      }}>
      <Box
        as="div"
        backgroundColor={'#665BE0'}
        height={active === code ? '100%' : '30px'}
        width="4px"
      />

      <Box w={'400px'}>
        <Text fontSize="24px" fontWeight={700} color={colors.brand.darkColors[0]}>
          {text}
        </Text>
        {active === code && (
          <Text fontWeight={'light'} color="#4A485F" mt={5}>
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  )
}
