import { Box, Image } from '@chakra-ui/react'

interface AnimatedImageProps {
  image: string
  progress?: number
  STANDARD?: number
  START_ANIMATION?: number
}

export const AnimatedImage = ({ image }: AnimatedImageProps) => {
  return (
    <Box>
      <Image
        alt="feature-images"
        key={`${image}`}
        src={image}
        transition={{ ease: 'anticipate' }}
        data-aos="zoom-in"
        data-aos-duration="200"
        data-aos-delay="0"
        width={'558px'}
        height="450px"
        objectFit={'contain'}
      />
    </Box>
  )
}

export default AnimatedImage
